import React from "react";
import AllNews from './DashboardPage/AllNews'
import MyNews from './DashboardPage/MyNews'
import Calendar from './DashboardPage/Calendar'
import { SubHeader } from "../../_metronic/layout/components/subheader/SubHeader";

export default function () {
  return <>
    {/* begin::Dashboard */}
    <SubHeader />

    {/* begin::Row */}
    <div className="row">
      <div className="col-lg-6 col-xxl-4">
        <MyNews className="card-stretch gutter-b" />
      </div>

      <div className="col-lg-6 col-xxl-4">
        <AllNews className="card-stretch gutter-b" />
      </div>

      <div className="col-lg-6 col-xxl-4">
        <Calendar className="gutter-b card-stretch" chartColor="danger" />
      </div>

    </div>
    {/* end::Row */}

    {/* end::Dashboard */}
  </>;
}
