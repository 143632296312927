/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Calendar from './DashboardPage/Calendar'
import { NavLink } from "react-router-dom";

export default function () {
  return <>
    <div class="py-5 py-lg-10"></div>

    <div className="card card-custom gutter-b">
      <div className="card-body">
        <div className="d-flex mb-9">
          <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
          <div class="symbol symbol-50 symbol-lg-120 symbol-2by3 flex-shrink-0 mr-4">
            <div class="symbol-label" style={{
              backgroundImage: `url('${toAbsoluteUrl(`/media/stock-600x400/img-1.jpg`)}')`,
            }}></div>
            </div>
            <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between flex-wrap mt-1">
              <div className="d-flex mr-3">
                <a href="#" className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">Fiat Chrysler Automobiles</a>
                <a href="#">
                  <i className="flaticon2-correct text-success font-size-h5"></i>
                </a>
              </div>
              <div className="my-lg-0 my-3">
                <a href="#" className="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">ask</a>
                <a href="#" className="btn btn-sm btn-info font-weight-bolder text-uppercase">request more details</a>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between mt-1">
              <div className="d-flex flex-column flex-grow-1 pr-8">
                <div className="d-flex flex-wrap mb-4">
                  <a href="#" className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  Automotive</a>
                  <a href="#" className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  Auto manufacturer</a>
                  <a href="#" className="text-dark-50 text-hover-primary font-weight-bold">
                  <i className="flaticon2-placeholder mr-2 font-size-lg"></i>Italy</a>
                </div>
                <span className="font-weight-bold text-dark-50">I distinguish three main text objectives could be merely to inform people.</span>
                <span className="font-weight-bold text-dark-50">A second could be persuade people.You want people to bay objective</span>
              </div>
            </div>
          </div>
        </div>
        <div className="separator separator-solid"></div>
        <div className="d-flex align-items-center flex-wrap mt-8">
          <div className="d-flex align-items-center mr-10 mb-2">
            <span className="mr-4">
            <i className="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">Moody's</span>
              <span className="font-weight-bolder font-size-h5">Ba1</span>
            </div>
          </div>
          <div className="d-flex align-items-center mr-10 mb-2">
            <span className="mr-4">
            <i className="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">SAP</span>
              <span className="font-weight-bolder font-size-h5">BB+</span>
            </div>
          </div>
          <div className="d-flex align-items-center mr-10 mb-2">
            <span className="mr-4">
            <i className="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">Fitch</span>
              <span className="font-weight-bolder font-size-h5">BBB-</span>
            </div>
          </div>
          <div className="flex-lg-fill"></div>
          <div className="d-flex align-items-center mr-10 mb-2">
            <span className="mr-4">
            <i className="flaticon-pin display-4 text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">Key country of risk</span>
              <span className="font-weight-bolder font-size-h5">Italy</span>
            </div>
          </div>
          <div className="d-flex align-items-center mr-10 mb-2">
            <span className="mr-4">
            <i className="flaticon-pin display-4 text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-sm">All countries of risk</span>
              <span className="font-weight-bolder font-size-h5">Italy, Spain, Portugal</span>
            </div>
          </div>
          <div className="flex-lg-fill"></div>
          <div className="d-flex align-items-center mr-10 mb-2">
            <span className="mr-4">
              <i className="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column flex-lg-fill">
              <span className="text-dark-75 font-weight-bolder font-size-sm">73 Tasks</span>
              <a href="#" className="text-primary font-weight-bolder">View</a>
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <span className="mr-4">
              <i className="flaticon-chat-1 display-4 text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column">
              <span className="text-dark-75 font-weight-bolder font-size-sm">648 Comments</span>
              <a href="#" className="text-primary font-weight-bolder">View</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    {/* begin::Row */}
    <div className="row">
      <div className="col-lg-4 col-xxl-4">
        <About className="card-stretch gutter-b" />
      </div>

      <div className="col-lg-4 col-xxl-4">
        <CapitalStructure className="card-stretch gutter-b" />
      </div>

      <div className="col-lg-4 col-xxl-4">
        <Performance className="gutter-b card-stretch" chartColor="danger" />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row">
      <div className="col-lg-4">
        <News className="card-stretch gutter-b" />
      </div>
      <div className="col-lg-4">
        <Documents className="card-stretch gutter-b" />
      </div>
      <div className="col-lg-4">
        <Calendar className="gutter-b card-stretch" chartColor="danger" />
      </div>
    </div>
    {/* end::Row */}
  </>;
}

function About({ className, title, subtitle }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              About the Company
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0">
          Fiat Chrysler Automobiles N.V. was a multinational corporation resulting from the acquisition of Chrysler Group by Fiat S.p.A. The Italian holding company Exor was the largest shareholder and owner of voting rights. At the time it was the world's eighth largest auto maker. The group was established in October 2014.
        </div>
      </div>
      {/* end::Body */}
    </>
  );
}

function CapitalStructure({ className, title, subtitle }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Capital Structure
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0">
          <img src={toAbsoluteUrl("/media/demo/capital-structure.png")} alt="" className="w-100" />
        </div>
      </div>
      {/* end::Body */}
    </>
  );
}

function Performance({ className, title, subtitle }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Equity Performance vs Peers
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0">
          <img src={toAbsoluteUrl("/media/demo/performance.png")} alt="" className="w-100" />
        </div>
      </div>
      {/* end::Body */}
    </>
  );
}

function Documents({ className, title, subtitle }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Recent Documents
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0">
          Long text about a company
        </div>
      </div>
      {/* end::Body */}
    </>
  );
}

function News({ className, title, subtitle }) {
  const data = [
    { location: 'Brazil', name: 'Angra Partners', category: 'Oil', growth: '' },
    { location: 'Brazil', name: 'Vinci Partners', category: 'Finance', growth: '' },
    { location: 'Brazil', name: 'Artesia Gestao de Recursos', category: 'Fashion', growth: '' },
    { location: 'Brazil', name: 'Axxon', category: 'Oil', subcategory: 'Transportation', growth: '' },
    { location: 'Brazil', name: 'Bozano Investimentos', category: 'Finance', subcategory: 'Banking', growth: '' },
    { location: 'Brazil', name: 'Bridge Trust', category: '', growth: '' },
    { location: 'Brazil', name: 'BRL Trust Investimentos', category: '', growth: '' },
    { location: 'Brazil', name: 'BRZ Investimentos', category: '', growth: '' },
  ]

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              News
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0">
          {data.map((d, i) => (
            <div key={i} className="mb-6">
              <div className="d-flex align-items-top flex-grow-1">
                {/* begin::Section */}
                <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                  {/* begin::Info */}
                  <div className="d-flex flex-column align-items-cente py-2 flex-grow-1">
                    {/* begin::Title */}
                    <NavLink to="/company/angra" className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">
                      {d.name}
                    </NavLink>
                    {/* end::Title */}

                    {/* begin::Data */}
                    <span className="text-dark-50 font-weight-normal font-size-sm">
                      Temporary 3-week suspension of OPAP stores
                    </span>
                    {/* end::Data */}
                  </div>
                  {/* end::Info */}

                  {/* begin::Label */}
                  <span className="label label-lg label-light-info label-inline font-weight-bold py-4 mr-6">
                    News
                      </span>
                  {/* end::Label */}

                  <div class="mr-6">
                    <div class="text-dark-75 font-weight-bolder font-size-sm">21 mins ago</div>
                    <div class="font-weight-bold text-muted font-size-sm">Januay 12 2020 4:30pm</div>
                  </div>

                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Arrow-right.svg"
                        )}
                      ></SVG>
                    </span>
                  </a>
                </div>
                {/* end::Section */}
              </div>
              {/* end::Content */}
            </div>
          ))}
        </div>
      </div>
      {/* end::Body */}
    </>
  );
}
