/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu1 } from "../../../_metronic/_partials/dropdowns";

export default function Calendar({ className }) {
  const events = [
    { date: 'Jan 14', day: 'Saturday', title: 'Matalan', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
    { date: 'Jan 16', day: 'Monday', title: 'Angra Partners', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
    { date: 'Jan 16', day: 'Monday', title: 'Vinci Partners', description: <span>Press conference</span> },
    { date: 'Jan 16', day: 'Monday', title: 'Axxon', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
    { date: 'Jan 16', day: 'Monday', title: 'Bridge Trust', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
    { date: 'Jan 16', day: 'Monday', title: 'BTG Pactual', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
    { date: 'Jan 16', day: 'Monday', title: 'Bridge Trust', description: <span>Press conference</span> },
    { date: 'Jan 16', day: 'Monday', title: 'BTG Pactual', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
    { date: 'Jan 16', day: 'Monday', title: 'Bridge Trust', description: <span>Press conference</span>  },
    { date: 'Jan 16', day: 'Monday', title: 'BTG Pactual', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
    { date: 'Jan 16', day: 'Monday', title: 'Bridge Trust', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
    { date: 'Jan 16', day: 'Monday', title: 'BTG Pactual', description: <span><span className="label label-light-info label-inline font-weight-bold mr-1">Predicted</span> Q3 Earnings</span> },
  ]
  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">Calendar</span>
            {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
              890,344 Sales
            </span> */}
          </h3>
          <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu1 />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3">
            {events.map((event, index) => (
              <div key={index} className="timeline-item align-items-start">
                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                  {event.date}
                  <div className="text-muted font-weight-bold font-size-xs">
                    {event.day}
                  </div>
                </div>

                <div className="timeline-badge">
                  <i className={`fa fa-genderless text-primary icon-xl`}></i>
                </div>

                <div className="timeline-content pl-3">
                  <div className="font-weight-bolder text-dark-75 font-size-lg">
                    {event.title}
                  </div>
                  <div className="font-weight-mormal font-size-lg text-muted">
                    {event.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
