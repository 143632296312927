/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import dataPath from '../../../data/companies.csv.js'
import parse from 'csv-parse/lib/sync'

// const data = [
//   { location: 'Brazil', name: 'Angra Partners', category: 'Oil', growth: '' },
//   { location: 'Brazil', name: 'Vinci Partners', category: 'Finance', growth: '' },
//   { location: 'Brazil', name: 'Artesia Gestao de Recursos', category: 'Fashion', growth: '' },
//   { location: 'Brazil', name: 'Axxon', category: 'Oil', subcategory: 'Transportation', growth: '' },
//   { location: 'Brazil', name: 'Bozano Investimentos', category: 'Finance', subcategory: 'Banking', growth: '' },
//   { location: 'Brazil', name: 'Bridge Trust', category: '', growth: '' },
//   { location: 'Brazil', name: 'BRL Trust Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'BRZ Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'BTG Pactual', category: '', growth: '' },
//   { location: 'Brazil', name: 'DGF Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'Gavea Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'GP Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'Grupo Stratus', category: '', growth: '' },
//   { location: 'Brazil', name: 'Kinea', category: '', growth: '' },
//   { location: 'Brazil', name: 'Lions Trust', category: '', growth: '' },
//   { location: 'Brazil', name: 'Mantiq Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'Ouro Preto Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'Paraty Capital', category: '', growth: '' },
//   { location: 'Brazil', name: 'Patria Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'Rio Bravo Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'Tarpon Investimentos', category: '', growth: '' },
//   { location: 'Brazil', name: 'TMG Capital', category: '', growth: '' },
//   { location: 'Brazil', name: 'Trivella Investimentos', category: '', growth: '' },
// ]

const data = parse(dataPath, { columns: true })
console.log(data)

export default function ({ className }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              My Companies
          </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Tracking 395 companies
          </span>
          </h3>
          <div className="card-toolbar">
            <a
              href="#"
              className="btn btn-success font-weight-bolder font-size-sm"
            >
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                  className="h-50 align-self-center"
                ></SVG>
              </span>
            Track New Company
          </a>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ width: "20px" }}>
                    <label className="checkbox checkbox-lg checkbox-single">
                      <input type="checkbox" value="1" />
                      <span></span>
                    </label>
                  </th>
                  <th className="pr-0" style={{ width: "50px" }}>
                    Company
                </th>
                  <th style={{ minWidth: "200px" }} />
                  <th style={{ minWidth: "150px" }}>Category</th>
                  <th style={{ minWidth: "150px" }}>Growth</th>
                  <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                    Actions
                </th>
                </tr>
              </thead>
              <tbody>
                {data.map(d => (
                  <tr key={d['Issuer']}>
                    <td className="pl-0">
                      <label className="checkbox checkbox-lg checkbox-single">
                        <input type="checkbox" value="1" />
                        <span></span>
                      </label>
                    </td>
                    <td className="pr-0">
                      <div className="symbol symbol-50 symbol-light mt-1">
                        <span className="symbol-label">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                            className="h-75 align-self-end"
                          ></SVG>
                        </span>
                      </div>
                    </td>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        <NavLink className="menu-link" to="/company/angra">
                          {d['Issuer']}
                        </NavLink>
                      </a>
                      <span className="text-muted font-weight-bold text-muted d-block">
                        {d['Ticker']} Brazil
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {d.category}
                      </span>
                      <span className="text-muted font-weight-bold">
                        {d.subcategory}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 mr-2">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-muted mr-2 font-size-sm font-weight-bold">
                            65%
                      </span>
                          <span className="text-muted font-size-sm font-weight-bold">
                            Progress
                      </span>
                        </div>
                        <div className="progress progress-xs w-100">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            style={{ width: "65%" }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td className="pr-0 text-right">
                      <a
                        href="#"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Settings-1.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          ></SVG>
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
