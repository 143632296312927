export default `Issuer,Company Name,Ticker,Public/Private/Parent listed,Equity Ticker,Total Bonds O/S IN FX (M),Total Bonds O/S in USD (M),Capital Structure - Parent Name,Key shareholders,Specific Issuers,Other related issuers in group,Desc. of Issuer (If Applicable),Desc. of Company,BICS Level 1,BICS Level 2,Key country of risk /incorporation,Public Filing Sources,Company Public Sources,Password Protected?,Username,Password,Sends Email Alerts?,Emails Set Up?,Main News Sources,Bloomberg sources of press releases,Additional Valuable Information Sources
ABY Transmision Sur SA,,ABENGO,Private,-,USD 420m,$420,ATN SA/Peru,,ABY Transmision Sur SA,,,,Utilities,Utilities,PERU,,,,,,,,,,
Aegea,,AEGEBZ,,,"BRL 1.5B
USD 400m",$675,Greq Participacao e Administra,,"AEGEA Sanaemento e Participacoes SA
Grua Investimentos SA
Aegea Finance Sarl",,,,Utilities,Utilities,LUXEMBOURG,,,,,,,,,,
AES,,AES,Public,"AES, A1ES34, OH6G, OH6GL, A1ES34, AESCHF, AESEUR, AESGBX, AESUSD",USD 8.2B,"$8,220",AES Corp/The,,"AES Corp
AES Panama Generation Holdings
DPL Inc
IPALCO Enterprises
Indianapolis Poewr & Light Co
AES Argentina Generacion
AES Andres BV / Dominican Power Partners / Empresa Generadora de Electricidad
AES Panama
AES El Salvador Trust II",,,"The AES Corporation acquires, develops, owns, and operates generation plants and distribution
businesses in several countries. The Company sells electricity under long term contracts and serves
customers under its regulated utility businesses. AES also mines coal, turns seawater into drinking water, and develops alternative sources of energy.",Utilities,Power Generation,PANAMA,,,,,,,,,,
AES Gener SA,,AESGEN,Public,AESGENER,USD 2.6B,"$2,575",AES Corp/The,"Inversiones Cachagua (66.7%), AFP Habitat (4.9%)","Empresa Electrica Cochrane SpA
AES Gener SA
Sociedad Electrica Santiago SpA",,,"AES Gener S.A. generates and sells electricity in Chile, Colombia, and Argentina. The company
operates hydroelectric, solar and thermoelectric plants utilizing coal, natural gas, diesel and
biomass as fuel.",Utilities,Utilities,CHILE,,,,,,,,,,
Agua y Saneamientos Argentinos SA,,AGUSAN,Private,,USD 500M,$500,Agua y Saneamientos Argentinos,,,,,,Utilities,Utilities,ARGENTINA,,,,,,,,,,
Gas Natural de Lima y Callao SA,,CALLAO,Private,,"PEN 545M
USD 320M",$470,Grupo Energia Bogota SA ESP,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Capex SA,,CAPXAR,Public,"CAPX, CAPXD",USD 300M,$300,Wild SA,Cos Asociadas Petroleras (75.4%),"Capex SA, Hychico SA (loans)",,,"Capex S.A. generates and distributes electricity to industrial customers, and produces and markets oil, propane, butane, and gasoline.",Utilities,Utilities,ARGENTINA,,,,,,,,,,
Celeo Redes Operacion Chile SA,,CELEO,Private,,USD 370M,$370,Cantiles XXI SL,,"Celeo Redes Brasil
Celeo Fotovoltaico SA
Elecnor SA (loans)
Enerfin Energy Co of Canada (loans)
Dioxipe Solar sl (loans)","Celeo Redes Operacion Chile SA (CELEO)
Celeo Redes Chile Ltda (CEREBR)
Elecnor Eficiencia Enegetica (ENOSM)
Ventos do Sul Energia Sia (VSULBZ)
Aries solar Termoelectrica (ARIES; loans)
Galicia Vento SL (GALVEN)",,,Utilities,Utilities,CHILE,,,,,,,,,,
Comision Federal de Electricidad,,CFELEC,Private,,"MXN 59B
USD 8.4B","$11,370",Comision Federal de Electricid,,"Comision Federal de Electricidad
Bank of America Mexico / Comision Federal de Electricidad
Acciones y Valores Banamex / Comision Federal de Electricidad",,,,Utilities,Utilities,MEXICO,,,,,,,,,,
Colbun SA,,COLBUN,Public,"COLBUN, COLBUCLP ",USD 1.2B,"$1,160",Colbun SA,"Minera Valparaiso (35.2%)
Forestal Cominco (14%)
AntarChile (9.6%)
AFP Habitat (5.5%)","Colbun SA (COLBUN)
Fenix Power Peru SA (FENIPE)",,,"Colbun S.A. produces, distributes, and supplies electricity to the central region of Chile. Through its subsidiaries, the Company also transports and markets natural gas and other fuels.
",Utilities,Power Generation,CHILE,,,,,,,,,,
Cometa Energia SA de CV,,COMENG,Private,,USD 805,$805,Cometa Energia SA de CV,,Cometa Enegia SA de CV,,,,Utilities,Power Generation,MEXICO,,,,,,,,,,
Engie Energia Chile SA,,ECLCI,Public,ECL,USD 850M,$850,Engie SA,"Engie Latam (52.8%)
AFP Cuprum (6.01%)",Engie Energia Chile SA,,,"Engie Energia Chile SA purchases, produces, distributes and transmits electrical energy in the First and Second Regions of Chile. The Company also purchases, sells and transports liquid, solid and gaseous fuels and provides consulting services related to maintenance of electrical systems.",Utilities,Utilities,CHILE,,,,,,,,,,
Empresa Distribuidora Y Comercializadora Norte,,EDNAR,Parent listed,"EDN, EDNC, EDND, PWD1, EDN1EUR, EDN1GBP, EDN1USD",USD 100M,$100,Pampa Energia SA,National Social Security Agency (59.2%),Empresa Distribuidora Y Comercializadora Norte,,,Empresa Distribuidora y Comercializadora Norte S.A. distributes and sells electricity in the north-eastern region of greater Buenos Aires.,Utilities,Utilities,ARGENTINA,,,,,,,,,,
Empresas Publicas de Medellin ESP,,EEPPME,Private,,"COP 6.95T
USD 1.6B","$3,590",Empresas Publicas de Medellin,,Empresas Publicas de Medellin ESP,,,,Utilities,Utilities,COLOMBIA,,,,,,,,,,
Energuate Trust,,EGUATE,Private,,USD 330M,$330,Inkia Energy Ltd,,Energuate Trust,,,,Utilities,Utilities,CAYMAN ISLANDS,,,,,,,,,,
CA La Electricidad de Caracas,,ELECAR,Private,,USD 650M,$650,Corp Electrica Nacional SA,,CA La Electricidad de Caracas,,,,Utilities,Utilities,VENEZUELA,,,,,,,,,,
Empresa Distribuidora de Electricidad de Salta Edsa SA,,EMDERS,Private,,USD 36M,$36,Magintec SA,,Empresa Distribuidora de Electricidad de Salta ,,,,Utilities,Utilities,ARGENTINA,,,,,,,,,,
Emgesa SA ESP,,EMGSA,Private,,COP 2T,$590,Enel SpA,,Emgesa SA ESP,,,,Utilities,Power Generation,COLOMBIA,,,,,,,,,,
Empresa de Transmision Electrica SA,,EMTESA,Private,,USD 575M,$575,Republic of Panama,,,,,,Utilities,Utilities,PANAMA,,,,,,,,,,
Enel Chile SA,,ENELCH,,,,0,Enel SpA,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Enel Generacion Chile SA,,ENELGX,,,,0,Enel SpA,,,,,,Utilities,Power Generation,CHILE,,,,,,,,,,
Enel Americas SA,,ENRSIS,,,,0,Enel SpA,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Planta de Reserva Fria de Generacion de Eten SA,,ETENPE,,,,0,Planta de Reserva Fria de Gene,,,,,,Utilities,Power Generation,PERU,,,,,,,,,,
Fenix Power Peru SA (part of COLBUN),,FENIPE,,,,0,Colbun SA,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Grupo Energia Bogota SA ESP,,GEBCB,,,,0,Grupo Energia Bogota SA ESP,,,,,,Utilities,Utilities,COLOMBIA,,,,,,,,,,
Mexico Generadora de Energia S de rl,,GMEXIB,,,,0,Grupo Mexico SAB de CV,,,,,,Utilities,Power Generation,MEXICO,,,,,,,,,,
Genneia SA,,GNNEIA,,,,0,Genneia SA,,,,,,Utilities,Power Generation,ARGENTINA,,,,,,,,,,
Infraestructura Energetica Nova SAB de CV,,IENOVA,,,,0,Sempra Energy,,,,,,Utilities,Utilities,MEXICO,,,,,,,,,,
Inkia Energy Ltd,,INKENE,,,,0,Inkia Energy Ltd,,,,,,Utilities,Power Generation,BERMUDA,,,,,,,,,,
Kallpa Generacion SA,,KALLPA,,,,0,I Squared Capital Advisors US,,,,,,Utilities,Power Generation,PERU,,,,,,,,,,
Generacion Mediterranea SA,,ALBAAR,,,,0,Albanesi SA,,,,,,Utilities,Power Generation,ARGENTINA,,,,,,,,,,
Bahia Las Minas Corp,,BMINAS,,,,0,Bahia Las Minas Corp,,,,,,Utilities,Power Generation,PANAMA,,,,,,,,,,
Celsia SA ESP,,CELSIA,,,,0,Grupo Argos SA/Colombia,,,,,,Utilities,Power Generation,COLOMBIA,,,,,,,,,,
HSBC / Comision Federal de Electricidad,,CFEHSB,,,,0,Comision Federal de Electricid,,,,,,Utilities,Power Generation,MEXICO,,,,,,,,,,
Empresa Generadora de Electricidad Haina SA,,EGEHAI,,,,0,Empresa Generadora de Electric,,,,,,Utilities,Power Generation,DOMINICAN REPB.,,,,,,,,,,
Enel Generacion Peru SAA,,ENGEPE,,,,0,Enel SpA,,,,,,Utilities,Power Generation,PERU,,,,,,,,,,
Empresa de Energia del Pacifico SA ESP,,EPSACB,,,,0,Grupo Argos SA/Colombia,,,,,,Utilities,Power Generation,COLOMBIA,,,,,,,,,,
Generacion Mediterranea SA / Central Termica Roca SA,,GENCEA,,,,0,Albanesi SA,,,,,,Utilities,Power Generation,ARGENTINA,,,,,,,,,,
Hydro Caisan SA,,HCAIPN,,,,0,Panama Power Holdings Inc,,,,,,Utilities,Power Generation,PANAMA,,,,,,,,,,
Isagen SA ESP,,ISAGEN,,,,0,Brookfield Renewable Power Inc,,,,,,Utilities,Power Generation,COLOMBIA,,,,,,,,,,
MSU Energy SA / UGEN SA / UENSA SA,,MSUNRG,,,,0,MSU Energy Holding Ltd,,,,,,Utilities,Power Generation,ARGENTINA,,,,,,,,,,
Interamerican Energy Corp,,PAPOGE,,,,0,Interamerican Energy Corp,,,,,,Utilities,Power Generation,PANAMA,,,,,,,,,,
Las Perlas Norte SA,,PERLAN,,,,0,Glenfarne Group LLC,,,,,,Utilities,Power Generation,PANAMA,,,,,,,,,,
Central Termica Roca SA,,ROCAAR,,,,0,Albanesi Inversora SA,,,,,,Utilities,Power Generation,ARGENTINA,,,,,,,,,,
Albanesi SA,,,,,,0,Albanesi SA,,,,,,Utilities,Power Generation,ARGENTINA,,,,,,,,,,
Orazul Energy Egenor SCA,,ORAZEN,,,,0,Interconexion Electrica SA ESP,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Pampa Energia SA,,PAMPAR,,,,0,Pampa Energia SA,,,,,,Utilities,Utilities,ARGENTINA,,,,,,,,,,
Promigas SA ESP / Gases del Pacifico SAC,,PROMIG,,,,0,Grupo Aval Acciones y Valores,,,,,,Utilities,Utilities,MULT,,,,,,,,,,
Termocandelaria Power Ltd,,TERMOC,,,,0,Termocandelaria Power Ltd,,,,,,Utilities,Power Generation,CAYMAN ISLANDS,,,,,,,,,,
Cia de Transporte de Energia Electrica en Alta Tension Transener SA,,TRANAR,,,,0,Cia Inversora de Transmision E,,,,,,Utilities,Utilities,ARGENTINA,,,,,,,,,,
Consorcio Transmantaro SA,,TRANSM,,,,0,Interconexion Electrica SA ESP,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Elektra Noreste SA,,ELENOR,,,,0,AEI,,,,,,Utilities,Utilities,PANAMA,,,,,,,,,,
Empresa de Servicios Sanitarios de Los Lagos SA,,ESSAL,,,,0,Algonquin Power & Utilities Co,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
ATN 2 SA,,ATNSA,,,,0,Atlantica Sustainable Infrastr,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Atria Energia SAC,,ATENPE,,,,0,Atria Energia SAC,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Transelec SA,,TSELEC,,,,0,ETC Transmission Holdings SL,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Consorcio Energetico Punta Cana-Macao SA,,CEPMSA,,,,0,CEPM Energy Ltd,,,,,,Utilities,Utilities,DOMINICAN REPB.,,,,,,,,,,
Luz del Sur SAA,,LUZSUR,,,,0,China Three Gorges Corp,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Concesionaria Trasvase Olmos SA,,ODB,,,,0,Concesionaria Trasvase Olmos S,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Conelsur LT SAC,,CONLPE,,,,0,Conelsur LT SAC,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Desarrollos Hidraulicos de Cancun SA de CV,,DHICAN,,,,0,Desarrollos Hidraulicos de Can,,,,,,Utilities,Utilities,MEXICO,,,,,,,,,,
Inversora Electrica de Buenos Aires SA,,IEBA,,,,0,Empresa Inversora Energetica S,,,,,,Utilities,Utilities,ARGENTINA,,,,,,,,,,
Empresas Lipigas SA,,LIPIGAS,,,,0,Empresas Lipigas SA,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Codensa SA ESP,,CODENS,,,,0,Enel SpA,,,,,,Utilities,Utilities,COLOMBIA,,,,,,,,,,
Enlasa Generacion Chile SA,,ENLSCL,,,,0,Energia Latina SA,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Engie Energia Peru SA,,ENESUR,,,,0,Engie SA,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
YPF Energia Electrica SA,,YPFLUZ,,,,0,YPF Energia Electrica SA,,,,,,Utilities,Power Generation,ARGENTINA,,,,,,,,,,
Gases de Petroleo SA,,GASEPA,,,,0,Gases de Petroleo SA,,,,,,Utilities,Utilities,PANAMA,,,,,,,,,,
Transportadora Gas del Norte SA,,TGNOAR,,,,0,Gasinvest SA,,,,,,Utilities,Utilities,ARGENTINA,,,,,,,,,,
Gases de Occidente SA ESP,,OCCICO,,,,0,Grupo Aval Acciones y Valores,,,,,,Utilities,Utilities,COLOMBIA,,,,,,,,,,
Surtidora de Gases del Caribe SA ESP,,SURTCO,,,,0,Grupo Aval Acciones y Valores,,,,,,Utilities,Utilities,COLOMBIA,,,,,,,,,,
Interconexion Electrica SA ESP,,ISACB,,,,0,Interconexion Electrica SA ESP,,,,,,Utilities,Utilities,COLOMBIA,,,,,,,,,,
Red de Energia del Peru SA,,REDEN,,,,0,Interconexion Electrica SA ESP,,,,,,Utilities,Utilities,PERU,,,,,,,,,,
Transelca SA ESP,,TRANSE,,,,0,Interconexion Electrica SA ESP,,,,,,Utilities,Utilities,COLOMBIA,,,,,,,,,,
Aguas del Altiplano SA,,AGALCL,,,,0,Inversiones AYS UNO Ltda,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Aguas Araucania SA,,AGARCL,,,,0,Inversiones AYS UNO Ltda,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Aguas Magallanes SA,,BAMGCL,,,,0,Inversiones AYS UNO Ltda,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Empresa Electrica de la Frontera SA,,BFRON,,,,0,Inversiones Grupo Saesa Ltda,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Inversiones Electricas del Sur SA,,IESD,,,,0,Inversiones Grupo Saesa Ltda,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Sociedad Austral de Electricidad SA/Chile,,SAESA,,,,0,Inversiones Grupo Saesa Ltda,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Esval SA,,ESVAL,,,,0,Inversiones OTPPB Chile II Ltd,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Cia General de Electricidad SA,,CGECI,,,,0,Naturgy Energy Group SA,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Naturgy Mexico SA de CV,,NTGYSM,,,,0,Naturgy Energy Group SA,,,,,,Utilities,Utilities,MEXICO,,,,,,,,,,
Nuevosur SA/Chile,,BANSM,,,,0,Nuevosur SA/Chile,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Administracion Nacional de Usinas y Trasmisiones Electricas - UTE,,UTEUY,,,,0,Oriental Republic of Uruguay,,,,,,Utilities,Utilities,URUGUAY,,,,,,,,,,
Sistema De Transmision Del Sur SA,,BSTSCL,,,,0,Sociedad Austral de Generacion,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Chilquinta Energia SA,,SRE,,,,0,State Grid Corp of China,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,
Aguas Andinas SA,,AGUAND,,,,0,Suez SA,,,,,,Utilities,Utilities,CHILE,,,,,,,,,,`