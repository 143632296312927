/**
 * High level router.
 */

import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import DashboardPage from "./pages/DashboardPage";
import CompaniesPage from "./pages/CompaniesPage";
import CompanyPage from "./pages/CompanyPage";
import Settings from "./pages/Settings";

const GoogleMaterialPage = lazy(() =>
    import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
    import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
    import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
    import("./modules/UserProfile/UserProfilePage")
);

export function Routes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                    <Layout>
                        <Suspense fallback={<LayoutSplashScreen />}>
                            <Switch>
                                {
                                    /* Redirect from root URL to /dashboard. */
                                    <Redirect exact from="/" to="/dashboard" />
                                }
                                <ContentRoute path="/dashboard" component={DashboardPage} />
                                <ContentRoute path="/companies" component={CompaniesPage} />
                                <ContentRoute path="/company" component={CompanyPage} />
                                <ContentRoute path="/settings" component={Settings} />

                                <Route path="/google-material" component={GoogleMaterialPage} />
                                <Route path="/react-bootstrap" component={ReactBootstrapPage} />
                                <Route path="/e-commerce" component={ECommercePage} />
                                <Route path="/user-profile" component={UserProfilepage} />
                                <Redirect to="error/error-v1" />
                            </Switch>
                        </Suspense>
                    </Layout>
                )}
        </Switch>
    );
}
