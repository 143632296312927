import React from "react";
import { Aside } from "../../_metronic/layout/components/aside/Aside";

export default function () {
  return <>
    <div className="d-lg-flex flex-row-fluid">

      <div className="content-wrapper flex-row-fluid">
        {/* begin::Row */}
        <div className="row">
          <div className="col-lg-6 col-xxl-3">
            <Aside />
          </div>
          <div className="col-lg-6 col-xxl-9">
            <div className={`card card-custom`}>
              {/* begin::Header */}
              <div className="card-header border-0 py-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label font-weight-bolder text-dark">
                    Settings
                  </span>
                </h3>
                <div className="card-toolbar">
                  <a
                    href="/"
                    className="btn btn-success font-weight-bolder font-size-sm"
                  >
                    Track New Company
                  </a>
                </div>
              </div>
              {/* end::Header */}
            </div>
          </div>
        </div>
        {/* end::Row */}
      </div>
    </div>
  </>;
}
