import React from "react";
import { ListsWidget10, ListsWidget11 } from "../../_metronic/_partials/widgets";
import Companies from './DashboardPage/Companies'

export default function () {
  return <>
    <div class="py-5 py-lg-10"></div>

    {/* begin::Row */}
    <div className="row">
      <div className="col-lg-6 col-xxl-12">
        <Companies className="card-stretch gutter-b" />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row">
      <div className="col-lg-6">
        <ListsWidget10 className="card-stretch gutter-b" />
      </div>
      <div className="col-lg-6">
        <ListsWidget11 className="card-stretch gutter-b" />
      </div>
    </div>
    {/* end::Row */}
  </>;
}
