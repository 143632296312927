/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { NavLink } from "react-router-dom";

const data = [
  { location: 'Brazil', name: 'Angra Partners', category: 'Oil', growth: '' },
  { location: 'Brazil', name: 'Vinci Partners', category: 'Finance', growth: '' },
  { location: 'Brazil', name: 'Artesia Gestao de Recursos', category: 'Fashion', growth: '' },
  { location: 'Brazil', name: 'Axxon', category: 'Oil', subcategory: 'Transportation', growth: '' },
  { location: 'Brazil', name: 'Bozano Investimentos', category: 'Finance', subcategory: 'Banking', growth: '' },
  { location: 'Brazil', name: 'Bridge Trust', category: '', growth: '' },
  { location: 'Brazil', name: 'BRL Trust Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'BRZ Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'BTG Pactual', category: '', growth: '' },
  { location: 'Brazil', name: 'DGF Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'Gavea Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'GP Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'Grupo Stratus', category: '', growth: '' },
  { location: 'Brazil', name: 'Kinea', category: '', growth: '' },
  { location: 'Brazil', name: 'Lions Trust', category: '', growth: '' },
  { location: 'Brazil', name: 'Mantiq Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'Ouro Preto Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'Paraty Capital', category: '', growth: '' },
  { location: 'Brazil', name: 'Patria Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'Rio Bravo Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'Tarpon Investimentos', category: '', growth: '' },
  { location: 'Brazil', name: 'TMG Capital', category: '', growth: '' },
  { location: 'Brazil', name: 'Trivella Investimentos', category: '', growth: '' },
]

export default function ({ className, title, subtitle }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              My companies
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Tracking 398 companies
            </span>
          </h3>
          <div className="card-toolbar">
            <a
              href="#"
              className="btn btn-success font-weight-bolder font-size-sm"
            >
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                  className="h-50 align-self-center"
                ></SVG>
              </span>
            Track New Company
          </a>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0">
          {data.map((d, i) => (
            <div key={i} className="mb-6">
              <div className="d-flex align-items-top flex-grow-1">
                <div class="symbol symbol-40 symbol-2by2 py-3 flex-shrink-0 mr-4">
                  <div class="symbol-label" style={{
                    backgroundImage: `url('${toAbsoluteUrl(`/media/stock-600x400/img-${i}.jpg`)}')`,
                  }}></div>
                </div>

                {/* begin::Section */}
                <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                  {/* begin::Info */}
                  <div className="d-flex flex-column align-items-cente py-2 flex-grow-1">
                    {/* begin::Title */}
                    <NavLink to="/company/angra" className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">
                      {d.name}
                    </NavLink>
                    {/* end::Title */}

                    {/* begin::Data */}
                    <span className="text-dark-50 font-weight-normal font-size-sm">
                      Temporary 3-week suspension of OPAP stores
                    </span>
                    {/* end::Data */}
                  </div>
                  {/* end::Info */}

                  {/* begin::Label */}
                  <span className="label label-lg label-light-info label-inline font-weight-bold py-4 mr-6">
                    News
                      </span>
                  {/* end::Label */}

                  <div class="mr-6">
                    <div class="text-dark-75 font-weight-bolder font-size-sm">21 mins ago</div>
                    <div class="font-weight-bold text-muted font-size-sm">Januay 12 2020 4:30pm</div>
                  </div>

                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Arrow-right.svg"
                        )}
                      ></SVG>
                    </span>
                  </a>
                </div>
                {/* end::Section */}
              </div>
              {/* end::Content */}
            </div>
          ))}
        </div>
      </div>
      {/* end::Body */}
    </>
  );
}
